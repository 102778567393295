
import { defineComponent } from "vue";
import * as icons from "@element-plus/icons";

export default defineComponent({
  components: { ...icons },
  setup() {
    let iconNames = Object.keys(icons);
    return {
      iconNames: { ...iconNames }
    };
  }
});
